// Agents tab styles
$plot-breakpoint: $screen-lg-up;
$gray-agents: #bebcb5;

.agents-header {
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  align-items: end;

  & > .heading--3 {
    justify-self: end;
  }
}

.agent {
  background-color: $beige-light;
  background-repeat: repeat;
  background-position: 0 0;
  box-shadow: 0 2px 3px 0 $brown-light;
  margin-top: 1rem;
  margin-left: 0.5rem;
  padding: 0.5rem;

  // Override the colour set on <a> tags
  a {
    color: $inverse-link-color;

    &:hover {
      color: $inverse-link-hover-color;
    }
  }
}

.agent::after {
  content: '';
  display: block;
  clear: both;
}

.free-agent,
.working-agent {
  @extend .agent;

  background-color: $gray-agents;
  background-image: url('https://images.fallenlondon.com/static/bg-paper_grey.png');
  margin-left: 0;
}

.agent-report {
  background-color: $gray-agents;
  background-image: url('https://images.fallenlondon.com/static/bg-paper_grey.png');
  background-repeat: repeat;
  background-position: 0 0;
  box-shadow: 0 2px 3px 0 $brown-light;
  margin-top: 1rem;
  margin-left: 0.25rem;
  padding: 0.5rem;
}

.agent-report-branch-container {
  margin-left: -0.5rem;
}

.agent-header-row {
  display: grid;
  grid-template-columns: 1fr auto;
  margin-left: 4px;
}

.agent-body-row {
  display: grid;
  grid-template-rows: auto 1fr;
  margin-left: 4px;
  row-gap: 0.5rem;

  @media (min-width: $screen-md-up) {
    column-gap: 0.5rem;
    grid-template-columns: auto 1fr;
  }
}

.agent-summary {
  display: grid;
  grid-template-columns: auto 1fr;

  @media (min-width: $screen-md-up) {
    grid-template-columns: auto 16rem;
  }
}

.agent-details {
  hr {
    margin: 2rem 0 0.5rem;
  }
}

.agent-portrait {
  margin: 0;
  border: solid 4px $gray-darkish;
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.5);
  height: 100px;
  width: 78px;
}

.agent-stat {
  display: grid;
  grid-template-rows: 40px 30px;
  row-gap: 0.25rem;

  img {
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  }

  padding: 0px;
  border-radius: 5px;
  border: solid 1px rgba(0, 0, 0, 0.6);
  height: 70px;
  background-color: $gray-dark;
}

.agent-stat-new-row {
  grid-column: 1;
}

.agent-stat-level {
  color: $beige-light;
  font-family: $font-family-serif;
  height: 100%;
  text-align: center;
  font-weight: bolder;
}

.agent-stat-bonus {
  color: $blue-dark;
}

.agent-inventory {
  display: grid;
  grid-template-columns: repeat(auto-fill, 36px);
  gap: 12px;

  margin: 0.25rem 12px 0.5rem 0;

  @media (min-width: $screen-md-up) {
    margin: 0.25rem 0 0.5rem;
  }

  .agent-inventory-item--locked {
    margin-top: 0.5rem;
  }
}

.agent-inventory-item--locked {
  position: relative;
}

.agent-inventory-item--lock {
  height: 16px;
  width: 16px;
  position: absolute;
  top: -8px;
  right: -8px;
}

.agent-inventory--is-changing {
  align-items: center;
  display: grid;
  height: 44px;
  align-self: center;
}

.modal-dialog--agent-inventory-picker {
  background-color: $bg-color;
  background-image: none;
  border: solid 2px $gray-darkish;
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.5);
  padding: 0;
  width: 320px;
}

.agent-inventory-picker {
  height: auto;
  padding: 0.25rem 0.75rem;
  width: 100%;
}

.agent-inventory-picker-header {
  @extend .heading;
  @extend .heading--3;

  color: $beige-mid;
  margin-bottom: 0.5rem;
  padding: 0;
}

.agent-inventory-picker__scrollbox {
  max-height: 40vh;
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    height: 16px;
    width: 18px;
  }

  &::-webkit-scrollbar-button { // up/down buttons
    display: none;
    height: 0;
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: rgba(0, 0, 0, 0.5);
    border: solid 4px rgba(0, 0, 0, 0);
    border-radius: 9px;
  }
}

.agent-inventory-picker__items {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: calc(100% - 4px);
  gap: 0.75rem;
  margin: 2px 2px 0.75rem;
}

.agent-inventory-picker__item {
  @extend .icon--emphasize;
  @extend .icon--available-item;
}

.agent-inventory-picker__lent-item {
  outline: solid 2px $blue-dark !important;
  outline-offset: -2px;
}

.agent-inventory-picker__hinted-item {
  outline: solid 2px $beige-dark !important;
  outline-offset: -2px;
}

.agent-inventory-picker__secondary-description {
  color: $red-light;
  display: block;
  margin-bottom: 0.25rem;
}

.agent-assign-button {
  @extend .button;
  @extend .button--primary;

  margin-left: 0 !important;
}

// I'm not sure why, but multiplying by -1 does not seem to work as expected, so we need 2 constants
$agent-background-margin: calc((4px + 2px) - 1rem); // ((tab border inset) + (tab border width)) - (tab margin)
$agent-background-padding: calc(1rem - (4px + 2px)); // (tab margin) - ((tab border inset) + (tab border width))

.agent-assign-view,
.agent-report-view {
  hr {
    margin: $line-height-computed $agent-background-margin 0;
  }
}

.agent-assign-container,
.agent-report {
  background-color: $gray-agents;
  background-image: url('https://images.fallenlondon.com/static/bg-paper_grey.png');

  padding: $agent-background-padding;
  margin: 0 $agent-background-margin $agent-background-margin;
}

.agent-to-assign {
  display: grid;
  grid-template-columns: auto 1fr;
  margin-bottom: 0.5rem;

  img {
    border: solid 2px $gray-darkish;
    margin: 0 0.25rem 0 0;
  }

  align-items: center;
}

.agent-assigned {
  margin-bottom: 0.5rem;

  align-items: center;
}

.agent-header {
  margin-bottom: 0.5rem;
}

.plot-container,
.concern-container {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: auto 1fr;

  padding: 0.5rem;
  column-gap: 0.5rem;
  margin: 1rem 0;

  border-bottom: 1px solid $beige-mid;
  background-color: $beige-light;
  background-repeat: repeat;
  background-position: 0 0;
  box-shadow: 0 2px 3px 0 $brown-light;

  // Override the colour set on <a> tags
  a {
    color: $inverse-link-color;

    &:hover {
      color: $inverse-link-hover-color;
    }
  }
}

.concern__header {
  margin-bottom: 18px;
}

.plot-container::after,
.concern-container::after {
  content: '';
  display: block;
  clear: both;
}

.plot__image,
.concern__image {
  grid-area: 1 / 1 / 2 / 2;

  @media (min-width: $plot-breakpoint) {
    grid-area: 1 / 1 / 3 / 2;
  }
}

.plot__body,
.concern__body {
  grid-area: 1 / 2 / 2 / 3;

  // CMS content formatting
  & > p {
    hyphens: auto;
    text-align: justify;
  }

  @media (min-width: $plot-breakpoint) {
    grid-area: 1 / 2 / 2 / 3;
  }
}

.plot__buttons,
.concern__buttons {
  display: grid;
  align-items: end;

  grid-area: 2 / 1 / 3 / 3;

  @media (min-width: $plot-breakpoint) {
    grid-area: 2 / 2 / 3 / 3;
  }
}

.plot-for-concern {
  margin: 1rem 0 1rem 0.5rem;
}

.plot__result-container {
  margin: 0.5rem 0.5rem 0.5rem 0;
}

.plot__preparation {
  margin: 0.5rem 0 0;

  .buttons {
    margin-top: 1rem;
  }
}

.plot__summary-container {
  margin: 0 0.5rem;
}

.plot__duration {
  font-weight: bold;
}

.plot__tutorial {
  @extend .plot__duration;
}

.plot__tutorial-alert {
  color: $red-mid;
  font-style: italic;
}

.plot__hint {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1rem;

  align-items: center;

  img {
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  }
}

.plot__launch-buttons {
  display: grid;
  grid-template-columns: auto auto;
}

.plot__result {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 0.25rem;

  .agent-portrait {
    display: none;
  }

  @media (min-width: $screen-md-up) {
    grid-template-columns: auto 1fr;

    .agent-portrait {
      display: block;
      margin-left: 4px;
    }
  }

  .plot-container {
    padding: 0.75rem;
    column-gap: 0.5rem;
    margin: 0 0 0.5rem 0;
  }

  .plot__image {
    align-self: center;
  }

  .small-card__image {
    display: block;
    height: calc(130px - 18px);
    width: calc((130px - 18px) * 100 / 130);
  }

  .plot__body {
    margin-top: 0.25rem;
  }
}

.agent-assign-footer-row {
  margin-left: 0;
}

.free-agent-footer-row {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 0.75rem;
  align-items: end;

  .agent-footer-buttons {
    margin: 0 0 0 auto;

    .agent-assign-button {
      height: 40px;
      align-self: flex-end;
    }
  }

  .agent-inventory {
    grid-template-columns: repeat(auto-fit, minmax(36px, 1fr));
    margin-right: 0;
  }
}

.working-agent-footer-row {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 12px;
  align-items: end;

  @media (min-width: $screen-md-up) {
    align-items: center;
    grid-template-columns: 1fr 175px;
  }

  .agent-footer-buttons {
    margin: 0 0 0 auto;

    .agent-assign-button {
      height: 40px;
      align-self: flex-end;
    }
  }
}

.working-agent-elapsed-actions {
  font-weight: bold;
  text-align: center;
  width: 100%;
}

.agent-stat-container {
  display: grid;
  gap: 0.5rem;

  grid-template-columns: repeat(auto-fill, minmax(42px, 1fr));
  grid-auto-rows: 70px;
}

.agent-stat-wrapper {
  margin: 0 auto;
}
